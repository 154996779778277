body {
  margin: 0;
 /*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  font-family: 'Inter', sans-serif !important;
}

code {
 /*  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace; */
    font-family: 'Inter', sans-serif !important;
}

/* .css-1sra7t5-MuiTypography-root{
  font-family: 'Inter', sans-serif !important;
}
.css-pcufbg-MuiTypography-root{
  font-family: 'Inter', sans-serif !important;
} */
.MuiTypography-root ,
.MuiButton-root,
.MuiTableCell-root,
.MuiAvatar-root,
.MuiList-root .MuiMenuItem-root ,
.MuiOutlinedInput-root,
.MuiOutlinedInput-input,
.MuiTooltip-tooltip ,
.MuiTablePagination-root,
.MuiChip-root {
  font-family: 'Inter', sans-serif !important; 
}
.MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem{
  color: #7A7A9D !important
}

/* .MuiButtonBase-root-MuiMenuItem-root-MuiTablePagination-menuItem{
color:"#7A7A9D !important"
} */

.MuiList-root .MuiMenuItem-root.Mui-selected{
  background-color:#E1E8FF !important
}
.MuiList-root .MuiMenuItem-root {
  text-transform: capitalize
}


