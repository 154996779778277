.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}



kendo-pdf-document .pdf-signature-image {
  max-width: 100%;
}

kendo-pdf-document .pdf-signature-image img {
  width: 100%;
}



kendo-pdf-document tr td p {
  margin: 0;
}

p {
  margin: 0;
}

kendo-pdf-document .leavesCount {
  margin-left: 0;
}

kendo-pdf-document .leaveSection .inputLabels {
  padding-right: 0px;
}



kendo-pdf-document .hideSection {
  display: none;
}

kendo-pdf-document .salary-note {
  text-align: center;
  width: 100%;
  padding-left: 25px;
}

kendo-pdf-document .company-logo {
  height: 100%;
  width: 60%;
}

kendo-pdf-document .signatureLabel,
kendo-pdf-document .salaryslipLabel {
  font-weight: 700;
}

kendo-pdf-document .tableContent,
kendo-pdf-document .companyInfoNew,
kendo-pdf-document .signatureContainer,
kendo-pdf-document .pdfwrapper,
kendo-pdf-document .amountDetailGrid {
  max-width: 750px;
}

kendo-pdf-document .pdfwrapper {
  margin-top: 0;
  margin-bottom: 24px;
  border: none;
}


table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  font-size: 12px;
  text-align: left;
}
th {
  padding: 0;
font-size: 14px;
font-weight: 600;

}
 
th {
  background-color: #f2f2f2;
}

.total {
  font-weight: bold;
}
.right-align {
  text-align: right;
}